import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import rightSideColBg from "../images/home-right-column-vector.png"

const capsules = [
  {
    id: "728a5ca2246888ccd0602a767452da62",
    title: "Ensayos de\nLaboratorio",
    image: "../images/icono-ensayos-laboratorio.jpg",
    url: "./static/home_icons/icono-ensayos-laboratorio.jpg",
    route: "/ensayos_laboratorio",
  },
  {
    id: "2bc1495ef20c40cb4c3feac56ec3d13a",
    title: "Análisis\nde Falla",
    image: "../images/icono-analisis-falla.jpg",
    url: "./static/home_icons/icono-analisis-falla.jpg",
    route: "/analisis_falla",
  },
  {
    id: "1872107b72bca5b3d8a14a2788ee65f3",
    title: "Ingeniería\nInversa",
    image: "../images/icono-ing-inversa.jpg",
    url: "./static/home_icons/icono-ing-inversa.jpg",
    route: "/ingenieria_inversa",
  },
]

const IndexPage = () => {
  return (
    <Layout location="/">
      <SEO title="Home" />
      <div className="home-top-background" />
      <div className="container-fluid">
        <div className="home-top-content">
          <div className="row align-items-center">
            <div className="col-12 col-md-7 left-col d-flex align-items-center justify-content-center order-2 order-md-1">
              <div>
                <h2>SIMET Ingeniería</h2>
                <h2>Servicios Tecnológicos</h2>
                <ul className="bullet-list">
                  <li>
                    Investigación, docencia y ensayos en el área de Ciencia de
                    los Materiales.
                  </li>
                  <li>
                    Servicios de ensayos e ingeniería de materiales a empresas
                  </li>
                  <li>Acreditado por norma NCh-ISO-17025</li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-5 right-col order-1 order-md-2 d-flex justify-content-center">
              <img src={rightSideColBg} alt="Simet" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-bottom-content">
        <div className="background-image" />
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="text-center title">Nuestros Servicios</h2>
              <p className="lead mt-5">
                Nuestro laboratorio cuenta con equipos de última generación y un
                personal altamente calificado y profesional, capaces de entregar
                un servicio de primer nivel.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters justify-content-center">
          {capsules.map(e => {
            return (
              <div
                key={e.id}
                className="col-9 col-sm-4 col-lg-3 col-xl-2 service-capsule-container"
              >
                <div className="service-capsule">
                  <Link to={e.route}>
                    <div className="capsule-img-wrapper">
                      <img src={e.url} alt={e.title} className="img-fluid" />
                    </div>
                    {e.title.split("\n").map((text, index) => {
                      return (
                        <h5
                          className="text-center"
                          key={`title-${index}-${e.id}`}
                        >
                          {text}
                        </h5>
                      )
                    })}
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
